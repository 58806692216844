<template>
	<div class=" ">
		<div class=" ">
			<div class="container pa-10-20">
				<div class="">
					<div class="input_area">
						<div class="bright_input justify-space-between items-center ">
							<label class="tit">{{  $language.common.all }} <!-- 전체 --></label>
							<p class="toggle-group h_unset mt-10">
								<span
									class="btn-toggle " :class="{ on: is_all}"
									@click="setAll"
								></span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="subindex_item ">
			<div class="container pa-20">
				<template
					v-for="(grade, g_index) in items_grade"
				>
					<div
						v-if="grade.cartl_member_div_code != 'CA02500001'"
						:key="'grade_' + g_index"
						class="input_area mt-20 "
					>
						<div class="bright_input justify-space-between items-center ">
							<label class="tit">{{ grade.cartl_member_grade_name }}</label>
							<p class="toggle-group h_unset mt-10">
								<span
									class="btn-toggle " :class="{ on: grade.is_all}"
									@click="setGrade(grade, !grade.is_all)"
								></span>
							</p>
						</div>
					</div>
				</template>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'CartelVotingEditPermission'
		, props: ['user', 'edit_info']
		, data: function(){
			return {
				item_edit: {
				}
				, is_all: true
				, items_grade: []
			}
		}
		, computed: {

		}
		, methods: {
			getCartelGrade: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_open_cartel_member_grade_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.items_grade = result.data.cartl_member_grade_list
						console.log('getCartelGrade', this.items_grade)
						this.items_grade.filter( (item) => {
							this.$set(item, 'is_all', true)
							this.edit_info.vote_participation_possible_authority_grade_list.filter( (grade) => {
								if(item.cartl_member_grade_number == grade.cartl_member_grade_number){
									this.$set(item, 'is_all', grade.vote_participation_possible_fg == 'Y' ? true : false)
								}
							})
						})
						console.log(this.items_grade)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setGrade: function(grade, is_all){

				grade.is_all = is_all
				this.$emit('click', this.items_grade)
			}
			, setAll: function(){
				this.is_all = !this.is_all
				this.items_grade.filter( (item) => {
					this.$set(item, 'is_all', this.is_all)
				})
			}
		}
		, created() {
			let title = this.$language.voting.title_voting_permission
			this.$emit('onLoad', title)
			this.getCartelGrade()
		}
		, watch: {
			items_grade: {
				deep: true
				, handler: function(call){
					console.log(call)
					let t = true
					call.filter( (item) => {
						if(!item.is_all){
							t = false
						}
					})
					this.is_all = t
					this.$emit('click', this.items_grade)
				}
			}
		}
	}
</script>