<template>
	<div class="mt-10 ">
		<div class=" ">
			<div class="container pa-10-20">
				<div class="input_area">
					<div class="bright_input">
						<label for="cartelName">{{  $language.voting.title_voting_period }} <!-- 투표 기간 --></label>
						<div class="justify-space-between gap-10 text-center size-px-12 font-weight-500">
							<div class="box-shadow radius-20 pa-10 flex-1" @click="onEdit('start')">
								<div class="font-weight-bold size-px-24 color-bbb">START</div>
								<div class="mt-10">{{ start_date }}</div>
								<div class="mt-10">{{ start_time }}</div>
							</div>
							<div class="flex-column justify-center items-center">
								<img :src="require('@/assets/image/icon_next_double.svg')" class="width-80"/>
							</div>
							<div class="box-shadow radius-20 pa-10 flex-1 " @click="onEdit('end')">
								<div class="font-weight-bold size-px-24 color-bbb">END</div>
								<div class="mt-10">{{ end_date }}</div>
								<div class="mt-10">{{ end_time }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-30">
					<div v-if="false" class="input_area">
						<div class="bright_input justify-space-between items-center ">
							<label class="tit" for="cartelName">{{  $language.voting.title_voting_permission }} <!-- 투표 참여 권한 --></label>
							<img :src="require('@/assets/image/icon_right-gray.svg')" />
						</div>
						<p class="input_desc mt-10">{{ $language.voting.txt_voting_permission }} <!-- 투표에 참여할 수 있는 회원을 설정합니다 --> </p>
					</div>
					<div class="input_area mt-20 ">
						<div class="bright_input justify-space-between items-center ">
							<label class="tit">{{  $language.voting.title_voting_multiple }} <!-- 복수 선택 --></label>
							<SwitchOnOff
								:obj="edit_info"
								:target="'plulity_choice_fg'"
							></SwitchOnOff>
						</div>

						<p class="input_desc pb-0">{{ $language.voting.txt_voting_multiple }} <!-- 투표시 복수선택이 가능합니다 --> </p>
					</div>

					<div class="input_area mt-20 ">
						<div class="bright_input justify-space-between items-center ">
							<label class="tit">{{  $language.voting.title_voting_comment_able }} <!-- 댓글 작성 --></label>
							<SwitchOnOff
								:obj="edit_info"
								:target="'comment_writing_fg'"
							></SwitchOnOff>
						</div>

						<p class="input_desc pb-0">{{ $language.voting.txt_voting_comment_able }} <!-- 투표에 댓글이 작성 가능합니다 --> </p>
					</div>

					<div class="input_area mt-20 ">
						<div class="bright_input justify-space-between items-center ">
							<label class="tit">{{  $language.voting.title_voting_anonymous }} <!-- 익명 투표 --></label>
							<SwitchOnOff
								:obj="edit_info"
								:target="'anoyms_vote_fg'"
							></SwitchOnOff>
						</div>

						<p class="input_desc pb-0">{{ $language.voting.txt_voting_anonymous }} <!-- 투표 참여자를 노출하지 않습니다 --> </p>
					</div>
				</div>
			</div>
		</div>
		<div class="subindex_item ">
			<div class="container pa-20">
				<div class="input_area mt-20 ">
					<div class="bright_input justify-space-between items-center ">
						<label class="tit">{{  $language.voting.title_voting_rate }} <!-- 투표 참여율 표기 --></label>
					</div>

					<div class="bright_radio interest_area mt-10">
						<input
							v-model="edit_info.vote_partprate_mark_code"
							value="CA03800001"
							type="radio" id="CA03800001"
						>
						<label for="CA03800001">
							<p>{{ $language.voting.txt_voting_rate1 }}</p>
						</label>
					</div>
					<div class="bright_radio interest_area">
						<input
							v-model="edit_info.vote_partprate_mark_code"
							value="CA03800002"
							type="radio" id="CA03800002"
						>
						<label for="CA03800002">
							<p>{{ $language.voting.txt_voting_rate2 }}</p>
						</label>
					</div>
					<div class="bright_radio interest_area">
						<input
							v-model="edit_info.vote_partprate_mark_code"
							value="CA03800003"
							type="radio" id="CA03800003"
						>
						<label for="CA03800003">
							<p>{{ $language.voting.txt_voting_rate3 }}</p>
						</label>
					</div>
				</div>
			</div>
		</div>
		<PopupLayer
			v-if="is_on_edit"
			
		>
			<template
				v-slot:body
			>

				<div class="bg-white  radius-20 overflow-hidden">
					<div class="pa-20">
						<div class="">
							<h3 class="pop_tit  text-center under-line  ">{{ edit_date_type }}</h3>
						</div>
						<div class="ptb-30">
							<DatePicker class="mt-20" :date="edit_date" @click="setDate($event)" :is_prev="true" :is_next="true" ></DatePicker>

							<TimePicker
								@click="setTime($event)"
								:hour_="item_edit[edit_type].hour"
								:min_="item_edit[edit_type].min"
							></TimePicker>
						</div>
					</div>
					<div class="btn_wrap">

						<button
							@click="cancel"
							class="pop_btn btn_fill_lightgray"
						>취소</button>
						<button
							@click="click"
							class="pop_btn btn_fill_blue"
						>확인</button>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import SwitchOnOff from "@/components/SwitchOnOff";
export default {
	name: 'CartelVotingEditOption'
	,
	components: {SwitchOnOff, TimePicker, DatePicker, PopupLayer},
	props: ['user', 'edit_info']
	, data: function(){
		return {
			edit_type: 'start'
			, is_on_edit: false
			, item_edit: {
				start: {
					hour: this.edit_info.vote_startdt.split(' ')[1].split(':')[0]
					, min: this.edit_info.vote_startdt.split(' ')[1].split(':')[1].slice(0, 1)+'0'
					, date: this.edit_info.vote_startdt.split(' ')[0]
				}
				, end: {
					hour: this.edit_info.vote_enddate.split(' ')[1].split(':')[0]
					, min: this.edit_info.vote_enddate.split(' ')[1].split(':')[1].slice(0, 1)+'0'
					, date: this.edit_info.vote_enddate.split(' ')[0]
				}
				, plulity_choice_fg: 'Y'
				, comment_writing_fg: 'Y'
				, anoyms_vote_fg: 'Y'
				, vote_partprate_mark_code: 'CA03800001'
			}
		}
	}
	, computed: {
		start_date: function(){
			let t = ''
			let d = this.edit_info.vote_startdt.split(' ')
			t = this.$date.getHanguelDate(d[0])
			return t
		}
		, start_time: function(){
			let t = ''
			t = this.edit_info.vote_startdt
			let d = this.edit_info.vote_startdt.split(' ')
			t = this.$date.transTimeEdit(d[1])
			return t
		}
		, end_date: function(){
			let t = ''
			let d = this.edit_info.vote_enddate.split(' ')
			t = this.$date.getHanguelDate(d[0])
			return t
		}
		, end_time: function(){
			let t = ''
			let d = this.edit_info.vote_enddate.split(' ')
			t = this.$date.transTimeEdit(d[1])
			return t
		}
		, edit_date: function(){
			let t = ''
			if(this.edit_type == 'start'){
				t = this.edit_info.vote_startdt
			}else{
				t = this.edit_info.vote_enddate
			}
			return t
		}
		, edit_date_type: function(){
			let t = ''
			if(this.edit_type == 'start'){
				t = this.$language.voting.title_voting_period_start
			}else{
				t = this.$language.voting.title_voting_period_end
			}
			return t
		}
	}
	, methods: {
		onEdit: function(type){
			this.edit_type = type
			this.is_on_edit = true
		}
		, setDate: function(e){
			this.$set(this.item_edit[this.edit_type], 'date', e)
			console.log(this.item_edit)
		}
		, setTime: function({hour, min}){
			this.$set(this.item_edit[this.edit_type], 'hour', hour)
			this.$set(this.item_edit[this.edit_type], 'min', min)
			console.log(this.item_edit)
		}
		, cancel: function(){
			this.is_on_edit = false
		}
		, click: function(){

			console.log(this.edit_date, this.item_edit[this.edit_type].date + ' ' + this.item_edit[this.edit_type].hour + ':' + this.item_edit[this.edit_type].min)
			this.$emit('click'
				, this.edit_type
				, this.item_edit[this.edit_type].date + ' ' + this.item_edit[this.edit_type].hour + ':' + this.item_edit[this.edit_type].min
			)
			this.cancel()
		}
	}
	, created() {
		let title = this.edit_info.cartl_vote_number ? this.$language.voting.title_voting_modify : this.$language.voting.title_voting_new_setting
		this.$emit('onLoad', title)
	}
	, watch: {

	}
}
</script>

<style>
	.tit { margin-bottom: unset !important;}
</style>