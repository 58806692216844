<template>
	<div>
		<div class="cartel_cover position-relative ">
			<label class="box pa-5-10 radius-10 position-absolute size-px-12 bg-white-half" style="right: 10px; top: 10px; z-index: 9">
				<v-icon small class="">mdi-camera</v-icon>
				{{ $language.voting.regist_cover_image }}
				<InputFileNew class="fileform_hidden" accept="image/*" @change="setFile($event)" ref="file" file_upload_code_info="CM00700021" />
			</label>
			<div class="full-height" style=" overflow: hidden">

				<div
					v-if="edit_info.vote_img_url"
					class="cover_background "
				>
					<img
						:src="edit_info.vote_img_url"
						@error="$bus.$emit('onErrorImage', $event)"
						class="object-cover"
					/>
				</div>
				<div
					v-else-if="edit_info.cartl_vote_number"
					class="full-height"
				>
					<div
						v-for="(image, i_index) in edit_info.vote_thumbnail_img.vote_thumbnail_img_partial_list"
						:key="'image_' + i_index"
						:class="{ 'full-height' : edit_info.vote_thumbnail_img.vote_thumbnail_img_partial_list.length == 1 }"
					>
						<img
							:src="image.vote_thumbnail_img_url"
							:class="{ 'object-cover' : edit_info.vote_thumbnail_img.vote_thumbnail_img_partial_list.length == 1 }"
							class="width-100"
						/>
					</div>
				</div>

				<div
					v-else
					class="text-center size-px-12 text-shadow flex-column justify-center full-height color-white"
				>
					{{ $language.voting.txt_voting_cover_image }} <!-- 투표 메인 커버사진을 추가하세요 -->
					<br/>
					<span class=""> {{ $language.voting.txt_voting_cover_image2 }} <!-- 커버사진은 목록에서 노출됩니다--> </span>
				</div>
			</div>

		</div>

		<div class="pa-10 mt-10">
			<div class="input_area">
				<div class="bright_input">
					<label for="cartelName">{{  $language.voting.title_voting_title }}</label>
					<div class="input_utill">
						<input
							v-model="edit_info.vote_title"
							type="text" id="cartelName" :placeholder="$language.voting.txt_voting_title" maxlength="20"
						>
						<div class="input_utill_area">
							<div class="btn_del">
								<button
									v-if="edit_info.vote_title"
									@click="edit_info.vote_title = ''"
									class="active"
								><span class="hide">{{  $language.common.close }}</span></button>
							</div>
							<span class="input_text_count">{{ edit_info.vote_title.length }}/20</span>
						</div>
					</div>
				</div>
			</div>

			<div class="input_area mt-20">
				<div class="bright_input">
					<label for="cartelDesc">{{  $language.voting.title_voting_contents }}</label>
					<div class="input_utill">
							<textarea
								v-model="edit_info.vote_contents"
								type="text"
								id="cartelDesc"
								class="input-box"
								rows="10"
								:placeholder="$language.voting.txt_voting_contents" maxlength="200"
							></textarea>
					</div>
					<div class="text-right mt-10 size-px-13">
						<span class="input_text_count mr-10">{{ edit_info.vote_contents.length }}/200</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import InputFileNew from "@/components/InputFileNew";
	export default {
		name: 'CartelVotingEditInfo'
		, components: {InputFileNew}
		, props: ['user', 'edit_info']
		, data: function(){
			return {
				
			}
		}
		, methods: {
			setFile: function(e){
				this.$emit('click', e)
			}
		}
		, created() {
			console.log('edit_info', this.edit_info)
			let title = this.edit_info.cartl_vote_number ? this.$language.voting.title_voting_modify : this.$language.voting.title_voting_new
			this.$emit('onLoad', title)
		}
	}
</script>