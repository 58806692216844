<template>
	<div class="mt-20 justify-space-between gap-10 ">
		<select
			v-model="hour"
			class="input-box text-center"
			@change="click"
		>
			<option
				v-for="s_time in 24"
				:key="'s_time_' + s_time"
				:value="('0' + (s_time - 1)).slice(-2)"
			>{{ ('0' + (s_time - 1)).slice(-2) }} 시</option>
		</select>

		<select
			v-model="min"
			class="input-box text-center"
			@change="click"
		>
			<option
				v-for="s_min in 6"
				:key="'s_min_' + s_min"
				:value="('0' + (s_min - 1) + '0').slice(-2)"
			>{{ ('0' + (s_min - 1)  + '0').slice(-2)}} 분</option>
		</select>
	</div>
</template>

<script>
	export default {
		name: 'TimePicker'
		, props: ['hour_', 'min_']
		, data: function(){
			return {
				hour: this.hour_ ? this.hour_ : '00'
				, min: this.min_ ? this.min_.slice(0, 1)+'0' : '00'
			}
		}
		, methods: {
			click: function(){
				this.$emit('click', {hour: this.hour, min: this.min})
			}
		}
	}
</script>