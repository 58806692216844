<template>
	<div>
		<label
			class="toggle-group h_unset mt-10" :for="obj[target] != 'Y' ? 'Y' + a : 'N' + a"
		>
			<span
				class="btn-toggle"
				:class="{on: obj[target] != 'N'}"
			></span>
		</label>
		<input
			v-show="false"
			v-model="obj[target]" type="radio" value="Y" :id="'Y' + a"
		/>
		<input
			v-show="false"
			v-model="obj[target]" type="radio" value="N" :id="'N' + a"
		/>
	</div>
</template>

<script>
	export default{
		name: 'SwitchOnOff'
		, props: ['obj', 'target']
		, data: function(){
			return {
				a: Math.random()
			}
		}
	}
</script>