<template>
	<div class="full-height flex-column">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="prev"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div
				class="save"
			>
				<button
					@click="next"
					class="btn_save"
					:disabled="is_next"
				>다음</button>
			</div>
		</div>

		<div class="mt-50 full-height">
			<CartelvotingEditInfo
				v-if="step == 1"
				:user="user"
				:edit_info="item"
				@click="setFile"
				@onLoad="setTitle"
			>
			</CartelvotingEditInfo>
			<CartelVotingEditOption
				v-else-if="step == 2"
				:user="user"
				:edit_info="item"
				@click="setDate"
				@onLoad="setTitle"
			></CartelVotingEditOption>
			<CartelVotingEditPermission
				v-else-if="step == 3"
				:user="user"
				:edit_info="item"

				@click="setList"
				@onLoad="setTitle"
			></CartelVotingEditPermission>
		</div>
	</div>
</template>
<script>
	import CartelvotingEditInfo from "@/view/CartelVoting/CartelVotingEditInfo";
	import CartelVotingEditOption from "@/view/CartelVoting/CartelVotingEditOption";
	import CartelVotingEditPermission from "@/view/CartelVoting/CartelvotingEditPermission";
	export default {
		name: 'CartelVotingEdit'
		, props: ['user']
		, components: {CartelVotingEditPermission, CartelVotingEditOption, CartelvotingEditInfo}
		, data: function(){
			return {

				program: {
					title: this.$language.voting.title_voting_new
					, name: this.$language.voting.title_voting_new
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, e_id: this.$route.params.e_id
				, item: {
					vote_title: ''
					, vote_contents: ''
					, vote_img_url: ''
					, vote_startdt: this.$date.getNextDay(this.$date.getNow('-'), '-') + ' 00:00'
					, vote_enddate: this.$date.getNextDay(this.$date.getNow('-'), '-', 2) + ' 00:00'
					, vote_participation_possible_authority_grade_request_list: []
					, vote_participation_possible_authority_grade_list: []
					, vote_partprate_mark_code: 'CA03800001'
					, plulity_choice_fg: 'Y'
					, comment_writing_fg: 'Y'
					, anoyms_vote_fg: 'Y'
					, vote_original_img: {

					}
				}
				, item_voting: {

				}
				, step: 0
				, vote_participation_possible_authority_grade_request_list: []
			}
		}
		, computed: {
			is_next: function(){
				let t = true
				switch (this.step){
					case 1:
						if(this.item.vote_title && this.item.vote_contents && (this.item.vote_img_url || this.item?.vote_thumbnail_img?.vote_thumbnail_img_partial_list)){
							t = false
						}
						break;
					case 2:
						t = false
						break;
					case 3:
						t = false
						break;

				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_bbs_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
						}
						, type: true
					})
					if (result.success) {
						this.item = result.data.cartl_vote_base_info
						this.$set(this.item, 'vote_participation_possible_authority_grade_list', result.data.vote_participation_possible_authority_grade_list)
						this.$set(this.item, 'vote_thumbnail_img', result.data.vote_thumbnail_img)
						this.$set(this.item, 'vote_img_url', '')

						/*
						let sDate = this.item.vote_startdt.split('T')
						this.item.vote_startdt = sDate[0] + ' ' + sDate[1].slice(0, 5)
						let eDate = this.item.vote_enddate.split('T')
						this.item.vote_enddate = eDate[0] + ' ' + eDate[1].slice(0, 5)
						 */
						this.item.vote_participation_possible_authority_grade_request_list = result.data.vote_participation_possible_authority_grade_list
						this.step = 1

					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, getVotingInfo: async function() {
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_board
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
						}
						, type: true
					})
					if (result.success) {
						this.item_voting = result.data
						switch (this.item_voting.requester_cartl_member_grade_code) {
							case 'CA02500001':
								break
							case 'CA02500002':
								break
							case 'CA02500003':
								if (this.item_voting.vote_release_settup_fg != 'Y') {
									this.$emit('goBack')
								}
								break
						}
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, setFile(e){
				this.$set(this.item, 'vote_img_url', e)
			}
			, next: function(){

				if(this.item.cartl_vote_state_code == 'CA03700002'){
					this.postVoting()
				}else{
					this.step++
					if(this.step > 3){
						this.postVoting()
						this.step = 3
					}
				}
			}
			, prev: function(){
				if(this.step > 1){
					this.step--
				}else{
					this.$bus.$emit('to', { name: 'CartelVotingList', params: {idx: this.$route.params.idx, v_id: this.$route.params.v_id}})
				}
			}
			, setDate: function(type, e){
				console.log('setDate', type, e)
				if(type == 'start'){
					this.item.vote_startdt = e
				}else{
					this.item.vote_enddate = e
				}
			}
			, toItem: function(){
				this.step = 5
			}
			, postVoting: async function(){
				try {
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.post_voting_bbs_info

					let data = {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, cartl_vote_number: this.$route.params.e_id
						, cartl_vote_board_number: this.$route.params.v_id
						, vote_img_url: this.item.vote_img_url
						, vote_title: this.item.vote_title
						, vote_contents: this.item.vote_contents // "투표내용", ->required
						, vote_startdt: (this.item.vote_startdt+':00').slice(0, 19) // 투표시작일", ->required
						, vote_enddate: (this.item.vote_enddate+':00').slice(0, 19) // 투표종료일", ->required
						, vote_participation_possible_authority_grade_request_list: this.vote_participation_possible_authority_grade_request_list
						, plulity_choice_fg: this.item.plulity_choice_fg //"복수선택여부", ->required
						, comment_writing_fg: this.item.comment_writing_fg //"댓글작성여부", ->required
						, anoyms_vote_fg: this.item.anoyms_vote_fg //"익명투표여부", ->required
						, vote_partprate_mark_code: this.item.vote_partprate_mark_code //"투표참여율표기코드" ->required
					}
					if(this.e_id){
						if(this.item.cartl_vote_state_code == 'CA03700001' || this.item.cartl_vote_state_code == 'CA03700005'){
							url = this.$api_url.api_path.put_voting_bbs_temp
						}else{
							url = this.$api_url.api_path.put_voting_bbs_info
							data = {
								member_number: this.user.member_number
								, cartl_number: this.$route.params.idx
								, cartl_vote_number: this.$route.params.e_id
								, cartl_vote_board_number: this.$route.params.v_id
								, vote_img_url: this.item.vote_img_url
								, vote_title: this.item.vote_title
								, vote_contents: this.item.vote_contents // "투표내용", ->required
							}
						}
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: data
						, type: true
					})
					if (result.success) {
						this.$bus.$emit('to', { name: 'CartelVotingItems', params: { e_id: this.$route.params.e_id ? this.$route.params.e_id : result.data.cartl_vote_number}})
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, setList: function(items){
				console.log(items)
				this.vote_participation_possible_authority_grade_request_list = []
				items.filter( (item) => {
					if(item.is_all){
						this.vote_participation_possible_authority_grade_request_list.push({
							cartl_member_grade_number: item.cartl_member_grade_number
						})
					}
				})
			}
			, setTitle: function(title){
				console.log('setTitle', title)
				this.program.title = title
				this.$bus.$emit('onLoad', this.program)
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getVotingInfo()
			if(this.e_id){
				this.getData()
			}else{
				this.step = 1
			}

		}
	}
</script>